export const SUPPORTED_CREDIT_CARDS = [
  {
    name: 'amex',
    length: '15',
    prefixes: '34,37',
    checkdigit: true
  },
  {
    name: 'mastercard',
    length: '16',
    prefixes: '51,52,53,54,55',
    checkdigit: true
  },
  {
    name: 'visa',
    length: '13,16',
    prefixes: '4',
    checkdigit: true
  }
]
